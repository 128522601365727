<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="Orders">
      <template #header>
        <div class="grid sm:grid-cols-1 md:grid-cols-2">
          <div class="flex flex-wrap content-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Order List
            </h3>
          </div>
          <div class="flex flex-row-reverse">
            <asom-export-to-excel
              fileName="Order List"
              :fields="columns.fields"
              :labels="columns.labels"
              :data="columns.data"
            />
          </div>
        </div>
      </template>
      <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field label="Line" required>
          <asom-input-select
            :disabled="!canSelectLine"
            v-model="filters.line"
            :options="lineOptions"
          />
        </asom-form-field>
        <asom-form-field label="Station" required>
          <asom-input-select
            v-model="filters.station"
            :options="stationOptions"
          />
        </asom-form-field>
        <asom-form-field label="Expected Delivery Date" required>
          <asom-input-date-range
            v-model="filters.dateRange"
            datepicker-placement="bottom"
          />
        </asom-form-field>
        <asom-form-field label="Order Status">
          <asom-multi-select
            :objectModelValue="false"
            v-model="filters.orderStatuses"
            :options="orderStatusOptions"
          />
        </asom-form-field>
      </div>
      <template #footer>
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="getPageData(true)" />
      </template>
    </asom-card>
    <div class="pt-8">
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'inventoryOrderNo',
          'orderDate',
          'expectedDeliveryDate',
          'deliveryDate',
          'elapsedDays',
          'status',
        ]"
        :sortableColumns="[
          'inventoryOrderNo',
          'orderDate',
          'expectedDeliveryDate',
          'deliveryDate',
          'elapsedDays',
          'status',
        ]"
        :data="orderList"
        :searchableDateColumns="['orderDate', 'deliveryDate', 'expectedDeliveryDate']"
      >
        <template v-slot:inventoryOrderNo="scopedSlots">
          <button
            @click="navigateToView(scopedSlots.rowData)"
            class="text-button underline font-semibold"
          >
            {{ scopedSlots.data }}
          </button>
        </template>
        <template v-slot:orderDate="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.data) }}</span>
        </template>
        <template v-slot:deliveryDate="scopedSlots">
          <span
            v-if="
              allOrderStatuses.DELIVERY_FULFILLED.VALUE ==
                get(scopedSlots.rowData, 'statusEnum')
            "
            >{{ displayUtcDate(scopedSlots.data) }}</span
          >
          <span v-else>NA</span>
        </template>
        <template v-slot:expectedDeliveryDate="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.data) }}</span>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { mapGetters } from "vuex";
import { displayUtcDate, parseDateTime } from "@/helpers/dateTimeHelpers";
import { getListOfInventoryOrders } from "../../../services/inventory.service";
import { OrderStatus } from "../../../constants/APIEnums/inventoryEnums";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  name: "OrderMainPage",
  data() {
    return {
      allOrderStatuses: OrderStatus,
      filters: {
        line: this.$store.getters['auth/userLineOption'],
        station: null,
        dateRange: {
          start: moment().toDate(),
          end: moment()
            .add(1, "months")
            .toDate(),
        },
        orderStatuses: [],
      },
      isLoading: false,
      orderList: [],
      error: null,

      searchVal: null,
      filterEcho: 0,
    };
  },
  computed: {
    ...mapGetters({
      userLineId: "auth/userLineId",
      userStationId: "auth/userStationId",
      lineOptions: "smrtResource/lineOptions",
      canSelectLine: 'auth/canSelectLine',
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    orderStatusOptions() {
      return [
        {
          label: OrderStatus.IN_REVIEW.NAME,
          value: OrderStatus.IN_REVIEW.VALUE,
        },
        {
          label: OrderStatus.REJECTED.NAME,
          value: OrderStatus.REJECTED.VALUE,
        },
        {
          label: OrderStatus.PENDING_DELIVERY.NAME,
          value: OrderStatus.PENDING_DELIVERY.VALUE,
        },
        {
          label: OrderStatus.DELIVERY_FULFILLED.NAME,
          value: OrderStatus.DELIVERY_FULFILLED.VALUE,
        },
      ];
    },
    columns() {
      const fields = [
        "inventoryOrderNo",
        "orderDate",
        "expectedDeliveryDate",
        "deliveryDate",
        "elapsedDays",
        "status",
      ];
      const labels = [
        "Inventory Order No",
        "Order Date",
        "Expected Delivery Date",
        "Delivery Date",
        "Elapsed Days",
        "Status",
      ];
      const data = [];
      this.orderList.forEach((element, i) => {
        data[i] = fields.reduce((accm, field) => {
          let d = element[field];
          if (
            field == "orderDate" ||
            field == "expectedDeliveryDate" ||
            field == "deliveryDate"
          ) {
            d = displayUtcDate(d);
          }
          accm[field] = d;
          return accm;
        }, {});
      });
      return {
        fields,
        labels,
        data,
      };
    },
    queryParams() {
      let params = {
        skip: 0,
        take: 100,
      };
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["deliveryDateFrom"] = parseDateTime(this.filters.dateRange.start);
        params["deliveryDateTo"] = parseDateTime(this.filters.dateRange.end);
      }
      params["lineId"] = get(this.filters.line, "value", null);
      params["stationId"] = this.filters.station
        ? get(this.filters.station, "value")
        : null;
      params["statuses"] = this.filters.orderStatuses;
      return params;
    },
  },
  watch: {
    "filters.line": function(newValue) {
      if(!this.filterEcho || this.filterEcho > 1){
        if (newValue) {
          this.filters.station = null;
        }
      }
    },
  },
  mounted() {
    this.searchVal = getStorage("delivery-report");
    if(this.searchVal) {
      this.filterEcho = 1;

      // UTC时间转换成对应的本地时间
      if(this.searchVal.dateRange && this.searchVal.dateRange.start)  this.searchVal.dateRange.start = moment.utc(this.searchVal.dateRange.start).toDate();
      if(this.searchVal.dateRange && this.searchVal.dateRange.end) this.searchVal.dateRange.end = moment.utc(this.searchVal.dateRange.end).toDate();
      this.filters = this.searchVal;
    }
    this.$nextTick(() => {
      this.filters.station = this.$store.getters["smrtResource/stationOptionsByLineId"](
        this.userLineId, this.userStationId
      )
      this.getPageData();
    })
  },
  methods: {
    get,
    displayUtcDate,
    resetFilters() {
      this.filters.dateRange = null;
      this.filters.orderStatuses = [];
    },
    async getPageData(status) {
      if(status) setStorage("delivery-report", this.filters);
      this.isLoading = true;
      const resp = await getListOfInventoryOrders({
        ...this.queryParams,
      });
      if (resp.success) {
        this.orderList = get(resp.payload, "list", []);
        this.isLoading = false;
        this.error = null;
      } else {
        this.error = resp.payload;
        this.isLoading = false;
        this.$scrollTop();
        return;
      }
    },
    navigateToView(selectedOrder) {
      this.$router.push({
        name: "View Order",
        params: selectedOrder,
      });
    },
  },
};
</script>
